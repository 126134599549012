body {
  margin: 0;
  font-family: 'Exo 2', 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(247, 247, 247);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hideOnMobile{
  display:none!important;
}


@media (max-width:400px){
  .rdrMonth{
    width:22em
  }

  .MuiGrid-root .rccs, .MuiGrid-root .rccs .rccs__card{
    width:100%!important;
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  .force-print * {
    visibility: visible!important;
  }

  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}